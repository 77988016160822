import TrainIcon from '@mui/icons-material/Train';
import PaymentIcon from '@mui/icons-material/Payment';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ComputerIcon from '@mui/icons-material/Computer';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import ScannerIcon from '@mui/icons-material/Scanner';
import PhonelinkIcon from '@mui/icons-material/Phonelink';
import ContactsIcon from '@mui/icons-material/Contacts';
import EventNoteIcon from '@mui/icons-material/EventNote';
import SettingsPhoneIcon from '@mui/icons-material/SettingsPhone';

import { Box, Container, Divider, Stack, Typography } from '@mui/material'
import { CircleDescription } from '@/components/ui/CircleDescription'
import { Calendar } from '@/components/ui/Calendar';
import { Link } from 'react-router-dom';
import { DigitalCard } from '@/components/ui/DigitalCard';
import { SubjectCard } from '@/components/ui/SubjectCard';
import { Access } from '@/components/Access';
import { Title } from "@/components/ui/Title";
import { useEffect, useState } from 'react';
import { ApiService } from '@/service/api';
import { Notice } from '@/service/model';
import { NoticeList } from '@/components/NoticeList';

function Home() {
  const [noticeList, setNotiseList] = useState<Notice[]>([])

  useEffect(() => {
    ApiService.getNoticeTopList().then((list) => {
      setNotiseList(list);
    }).catch(() => {

    });
  }, []);

  return (
    <>
      <Stack
        position="relative"
        display="flex"
        justifyContent="center"
        sx={{
          backgroundImage: 'url(/photo01.jpg)',
          height: '600px',
          backgroundSize: 'cover',
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
          backgroundBlendMode: 'lighten',
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
        >
          <Stack
            maxWidth="350px"
            paddingX={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap={4}
            sx={{
              marginBottom: '32px',
              paddingBottom: '32px',
              width: {
                xs: '100%',
                lg: '60%',
              }
            }}
          >
            <Typography
              variant="subtitle1"
              component="h1"
              color="rgba(0, 0, 0, 0.7)"
              sx={{
                fontSize: '14px',
                textAlign: 'center',
              }}
            >
              御縁を大切に。一人ひとりを丁寧に。
            </Typography>
            <img
              src="/logo-gray.png"
              alt="御茶ノ水つばめ歯科・矯正歯科"
              style={{ width: '150px', display: 'block' }}
            />
            <img
              src="/home/top-text.png"
              alt="御茶ノ水つばめ歯科・矯正歯科"
              style={{ width: '100%', display: 'block', opacity: 0.7 }}
            />
          </Stack>
        </Box>

        <Box
          position="absolute"
          bottom="16px"
          justifyContent="center"
          gap={3}
          width="100%"
          sx={{
            display: { xs: 'none', md: 'flex' }
          }}
        >
          <CircleDescription
            icon={<TrainIcon color="secondary" fontSize="large" />}
            primaryText={(
              <>
                複数路線
                <br />
                通院可能
              </>
            )}
            secondaryText={(
              <>
                御茶ノ水駅、神保町駅
                <br />
                小川町駅、淡路町駅
              </>
            )}
          />
          <CircleDescription
            icon={<PaymentIcon color="secondary" fontSize="large" />}
            primaryText={(
              <>
                各種決済対応
              </>
            )}
            secondaryText={(
              <>
                クレジットカード
                <br />
                電子マネー
                <br />
                QRコード
              </>
            )}
          />
          <CircleDescription
            icon={<AccessTimeIcon color="secondary" fontSize="large" />}
            primaryText={(
              <>
                好きな時間に
                <br />
                WEB予約
              </>
            )}
            secondaryText={(
              <>
                ・朝8時開始(水・木)
                <br />
                ・夜20時終了(火・金)
              </>
            )}
          />
        </Box>
      </Stack >

      <Stack
        display="flex"
        gap={3}
        padding={3}
        sx={{
          backgroundImage: 'url(/background01.jpg)',
          backgroundSize: 'cover',
        }}
      >

        <Calendar
          id="medical_hours"
        />
      </Stack>

      <Container maxWidth="xl" style={{ padding: 0 }}>

        <Stack>
          <NoticeList
            list={noticeList}
          />
          <Box
            display="flex"
            justifyContent="end"
            paddingRight={2}
            paddingTop={1}
            paddingBottom={2}
          >
            <Link to="/notice">
              <Typography
                variant="body1"
                sx={{
                  '&:hover': {
                    textDecoration: 'underline',
                    color: '#06c',
                  }
                }}
              >
                お知らせ一覧へ
              </Typography>
            </Link>
          </Box>
          <Divider />
        </Stack>

        {/*
        <Stack
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap={2}
          marginY={2}
          padding={4}
        >
          <Typography
            variant="subtitle1"
            component="p"
            sx={{
              textAlign: 'center',
            }}
          >
            御縁を大切に。一人ひとりを丁寧に。をテーマに、デジタル診療に力を入れている歯科医院です。 矯正やインプラント、セラミック治療に対してデジタル治療を組み合わせ治療精度の向上を図ります。 複数の治療を組み合わせる場合には見積書の方に、大切な御友人を紹介頂いた方には双方に特典をおつけしますのでお気軽に御相談下さい。 一般保険診療全般から小児歯科治療、親知らずの抜歯などの口腔外科領域、根管治療、セラミック、インプラント、ワイヤー矯正、マウスピース矯正など幅広く対応いたします。
            <br /><br />
            スタッフ一同、ご来院を心よりお待ちしております。
          </Typography>

          <Typography variant="subtitle1" component="p">
            院長
          </Typography>
        </Stack>
        */}


        <Stack
          display="flex"
          justifyContent="center"
          gap={0}
          paddingY={4}
          paddingX={2}
          sx={{
            backgroundImage: 'url(/background01.jpg)',
            backgroundSize: 'cover',
            paddingY: {
              sm: 2,
              md: 4,
            }
          }}
        >
          <Typography
            variant="h2"
            textAlign="center"
            sx={{
              color: '#FFFFFF',
              fontSize: {
                xs: '20px',
                md: '32px',
              }
            }}
          >
            御茶ノ水つばめ歯科・矯正歯科
          </Typography>

          <Box
            margin="0 auto"
            display="flex"
            alignItems="flex-end"
          >
            <Typography
              variant="h2"
              sx={{
                color: 'rgb(66, 66, 66)',
                fontSize: {
                  xs: '30px',
                  md: '48px',
                }
              }}
            >
              4
            </Typography>
            <Typography
              variant="h2"
              sx={{
                color: '#FFFFFF',
                fontSize: {
                  xs: '20px',
                  md: '28px',
                },
                paddingBottom: '6px',
              }}
            >
              つのこだわり
            </Typography>
          </Box>
        </Stack>

        <Box
          display="flex"
          justifyContent="center"
          flexWrap="wrap"
          paddingY={2}
          sx={{
            gap: {
              xs: 0,
              sm: 1,
            }
          }}
        >

          <Stack
            sx={{
              width: {
                xs: '100%',
                sm: 'calc(50% - 8px)',
              }
            }}
          >
            <Stack
              sx={{
                backgroundImage: 'url(/home/image02.jpg)',
                backgroundSize: 'cover',
                backgroundPosition: '50%',
                height: '250px',
              }}
            >
            </Stack>
            <Stack
              display="flex"
              gap={2}
              padding={2}
            >
              <Typography
                variant="subtitle1"
                textAlign="center"
                sx={{
                  color: 'rgba(0, 0, 0, 0.87)'
                }}
              >
                歯の情報を共有
              </Typography>
              <Typography variant="body2" textAlign="left">
                ご自身では見えにくい歯の状態をマイクロスコープや口腔内スキャナーを用いて説明、治療が出来るようにしております。いきなり歯を削られてしまった。
                <br />
                などのトラブルが起こらないようにご納得いただいてから治療を行えるよう、普段から配慮しております。
              </Typography>
            </Stack>
          </Stack>

          <Divider
            sx={{
              marginY: 4,
              display: {
                sm: 'none',
              }
            }}
          />

          <Stack
            sx={{
              width: {
                xs: '100%',
                sm: 'calc(50% - 8px)',
              }
            }}
          >
            <Stack
              sx={{
                backgroundImage: 'url(/home/image07.jpg)',
                backgroundSize: 'cover',
                backgroundPosition: '50%',
                height: '250px',
              }}
            >
            </Stack>
            <Stack
              display="flex"
              gap={2}
              padding={2}
            >
              <Typography
                variant="subtitle1"
                textAlign="center"
                sx={{
                  color: 'rgba(0, 0, 0, 0.87)'
                }}
              >
                複数の治療をワンストップで
              </Typography>
              <Typography variant="body2" textAlign="left">
                矯正治療、インプラント治療、虫歯治療、歯周病治療、1Dayセラミック治療に対応していますので、治療を当院でまとめて進める事ができます。矯正治療中に虫歯治療を行ったり、ホワイトニング後に色を揃えたダイレクトボンディングなども行なっております。
              </Typography>
            </Stack>
          </Stack>

          <Divider
            sx={{
              marginY: 4,
              display: {
                sm: 'none',
              }
            }}
          />

          <Stack
            sx={{
              width: {
                xs: '100%',
                sm: 'calc(50% - 8px)',
              }
            }}
          >
            <Stack
              sx={{
                backgroundImage: 'url(/home/image08.jpg)',
                backgroundSize: 'cover',
                backgroundPositionX: 'center',
                backgroundPositionY: '10%',
                height: '250px',
              }}
            >
            </Stack>
            <Stack
              display="flex"
              gap={2}
              padding={2}
            >
              <Typography
                variant="subtitle1"
                textAlign="center"
                sx={{
                  color: 'rgba(0, 0, 0, 0.87)'
                }}
              >
                メタルフリーの歯並びへ
              </Typography>
              <Typography variant="body2" textAlign="left">
                当院では金属アレルギーへの配慮として銀歯の治療をおすすめしておりません。保険診療内でもCAD/CAM冠やインレーにて白い被せ物への治療を積極的に行っております。より強度や審美性を求める方向けにセラミックやジルコニアの選択肢もありますのでご相談の上で治療を行っております。
              </Typography>
            </Stack>
          </Stack>

          <Divider
            sx={{
              marginY: 4,
              display: {
                sm: 'none',
              }
            }}
          />

          <Stack
            sx={{
              width: {
                xs: '100%',
                sm: 'calc(50% - 8px)',
              }
            }}
          >
            <Stack
              sx={{
                backgroundImage: 'url(/home/image04.jpg)',
                backgroundSize: 'cover',
                backgroundPosition: '50%',
                height: '250px',
              }}
            >
            </Stack>
            <Stack
              display="flex"
              gap={2}
              padding={2}
            >
              <Typography
                variant="subtitle1"
                textAlign="center"
                sx={{
                  color: 'rgba(0, 0, 0, 0.87)'
                }}
              >
                早期発見の予防歯科治療を
              </Typography>
              <Typography variant="body2" textAlign="left">
                春になると飛来するつばめのように、患者さまにも定期的にご来院頂けるよう治療終了後の予防歯科にも力を入れております。虫歯は早期に発見出来れば小さな治療で済みますので長い目で見れば治療の費用を下げる事につながります。虫歯がなくても定期検診、クリーニングをおすすめしております。
              </Typography>
            </Stack>
          </Stack>

          <Divider
            sx={{
              marginY: 4,
              display: {
                sm: 'none',
              }
            }}
          />

        </Box>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap={2}
          marginY={2}
        >
          <div style={{
            position: 'relative',
            width: '100%',
            paddingTop: '56.25%' /* 16:9 Aspect Ratio */
          }}>
            <iframe
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%'
              }}
              src="https://www.youtube.com/embed/AIFlrbBP7oc?si=ezzqIyz_mCdL7QGd"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            />
          </div>
        </Box>

        <Stack
          display="flex"
          gap={2}
          marginY={4}
        >
          <Typography
            variant="h2"
            textAlign="center"
            sx={{
              color: 'rgba(0,0,0,0.67)',
              fontWeight: 700,
              border: 'solid 1px #000',
              borderRadius: '20px',
              width: 'fit-content',
              margin: '24px auto',
              padding: 2,
              fontSize: {
                xs: '20px',
                md: '32px',
              }
            }}
          >
            デジタル診療に特化した医院
          </Typography>

          <Box
            display="flex"
            justifyContent="center"
            gap={2}
            flexWrap="wrap"
          >
            <DigitalCard
              icon={ComputerIcon}
              title="カルテの電子化"
              text="カルテの電子化により情報の検索、共有を素早く行うことが出来ます。以前の治療内容を確認し易くなり、久々の電話予約でもすぐに検索できますのでお待たせする時間が短くなります。"
            />

            <DigitalCard
              icon={SmartphoneIcon}
              title="予約のデジタル化"
              text="24時間予約をお取り頂けるよう、このHPより予約ページに移行出来るようになっております。事前に質問内容を記入いただけますので治療の際がスムーズにご案内が可能となります。"
            />

            <DigitalCard
              icon={ScannerIcon}
              title="型取りは口腔内スキャナーで"
              text="自費診療の歯型取りは口腔内スキャナーで行います。セラミック、インプラント、マウスピース矯正、マウスガードなど。苦手な方も多い印象材での型取りはなるべく行いません。"
              alert="＊保険治療の場合、算定項目としてデジタル印象がない場合は従来通りの型取りとなります。"
            />

            <DigitalCard
              icon={PhonelinkIcon}
              title="デジタル同士の組み合わせ"
              text="３次元のCTレントゲン×インプラントのガイドドリル。CT×矯正ブラケットのデジタルセットアップ。口腔内スキャナー×マウスピース矯正orセラミックミリングなど。デジタル器具同士で相性の良い治療を多く取り入れています。"
            />

            <DigitalCard
              icon={ContactsIcon}
              title="オンライン相談にも対応"
              text="直接歯科医院まで来る時間が取れない方や遠方の方向けに、予約ページからオンライン治療相談の予約を取る事が出来ます。新しい試みですので、使用頂き易いように都度更新を行なっていく予定です。"
            />

            <DigitalCard
              icon={PaymentIcon}
              title="セルフ精算システム"
              text="スタッフの金額入力ミスやお釣りの間違いをなくし、スムーズな会計が行えます。各種お支払い方法が選べます。"
            />

          </Box>
          <Box
            p={4}
          >
            <Typography
              variant="body1"
              fontSize="16px"
              lineHeight={1.5}
              textAlign="center"
            >
              治療の合間に歯並びのデータを無料でお渡ししております。
              ご希望の方はスタッフにお伝えください。
              <br />
              参考データは
              <a
                href="https://tkminiapp.dental3dcloud.com/pagesOralReport/index?&id=32abb619-453c-5286-9db6-b34494c4faa3"
                style={{ textDecoration: 'underline' }}
                target="_blank">こちら</a>より。
            </Typography>
          </Box>

        </Stack>

        <Stack
          sx={{
            backgroundImage: 'url(/home/image06.jpg)',
            backgroundSize: 'cover',
            backgroundPosition: '50%',
            height: '400px',
            opacity: 0.8,
          }}
        ></Stack>

        <Stack
          marginY={4}
          component="section"
          id="medical_subject"
        >
          <Title title="診療科目" />

          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent="center"
            gap={2}
            paddingY={4}
            paddingX={2}
            sx={{
              backgroundColor: '#EEEEEE',
            }}
          >
            <SubjectCard
              src="/home/subject01.svg"
              title="一般歯科治療"
              text="虫歯の治療、根管治療、歯周病治療、顎関節症の対応など"
            />

            <SubjectCard
              src="/home/subject02.svg"
              title="予防歯科"
              text="歯で困る事が少なくなるように予防治療に力を入れています。"
            />

            <SubjectCard
              src="/home/subject03.svg"
              title="小児歯科"
              text="お子様が健やかに成長できるよう寄り添いながら治療を行います。"
            />

            <SubjectCard
              src="/home/subject04.svg"
              title="ホワイトニング"
              text="歯科専用の薬品を用いてホワイトニングを行います。"
            />

            <SubjectCard
              src="/home/subject05.svg"
              title="親知らずの抜歯"
              text="歯肉の腫れや口臭の原因になる場合は抜歯をおすすめします。"
            />

            <SubjectCard
              src="/home/subject06.svg"
              title="インプラント"
              text="歯を失ってしまった箇所に人工の歯根を埋め込み、歯を補う治療法です。"
            />

            <SubjectCard
              src="/home/subject07.svg"
              title="歯列矯正"
              text="ワイヤーやマウスピースを使用し全体の歯並びを改善します。"
            />

            <SubjectCard
              src="/home/subject08.svg"
              title="セラミック治療"
              text="銀歯をなくしたり、色のあった被せ物を作製します。"
            />

            <SubjectCard
              src="/home/subject09.svg"
              title="マウスピース"
              text="歯ぎしり用、顎関節症改善用、スポーツ用のマウスピースなど。"
            />

            <SubjectCard
              src="/home/subject10.svg"
              title="入れ歯"
              text="自然な見た目と快適な装着感で、毎日の食事も笑顔もより豊かに。"
            />

          </Box>
        </Stack>

        <Stack
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap={2}
          marginY={2}
        >
          <div style={{
            position: 'relative',
            width: '100%',
            paddingTop: '56.25%' /* 16:9 Aspect Ratio */
          }}>
            <iframe
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%'
              }}
              src="https://www.youtube.com/embed/6ILNAkFjvVE?si=OqYCp-MjT6-nNesl"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            />
          </div>

          <div style={{
            position: 'relative',
            width: '100%',
            paddingTop: '56.25%' /* 16:9 Aspect Ratio */
          }}>
            <iframe
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%'
              }}
              src="https://www.youtube.com/embed/ZXQ8Kc4tHZI?si=kZsgDAb95PPr2rqd"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            />
          </div>
        </Stack>


        <Stack marginY={4} >
          <Typography
            variant="h4" // タイトル用の大きなフォントを指定
            textAlign="center"
            paddingX={2}
            sx={{
              lineHeight: 2.5,
              letterSpacing: '2px',
              color: 'rgba(0,0,0,0.67)',
              fontStyle: 'italic',
              fontFamily: 'cursive',
              fontSize: '24px', // タイトル用の大きなフォントサイズ
              marginBottom: 4, // 心地よい歯科医院を創るの下にスペースを追加
            }}
          >
            心地よい歯科医院を創る
          </Typography>

          <Typography
            variant="body2"
            textAlign="center"
            paddingX={2}
            sx={{
              lineHeight: 2.5,
              letterSpacing: '2px',
              color: 'rgba(0,0,0,0.67)',
              fontStyle: 'italic',
              fontFamily: 'cursive',
              fontSize: '16px',
            }}
          >
            通って頂いた患者様が心から笑顔になれるよう医療人として<br />
            良い行いをし続ける。<br />
            幸運を運んでくると言われる「つばめ」の巣のような<br />
            歯科医院をつくりたいと思い、この歯科医院を開きました。<br />
            <br />
            歯の健康は幸福度に直結する大切な要素です。<br />
            長い人生、より健康で快適な日々を過ごす為のお手伝いをさせて頂きます。<br />
            一人ひとりとのご縁を大切にし、丁寧な治療を通して信頼して頂く。<br />
            そして定期的にメンテナンスを任せて頂けるようスタッフ一同努力してまいります。<br />
            治療の後には患者様の日々に幸せが増えるように。<br />
            また戻って来たくなるような歯科医院を目指しています。<br />
            些細な悩みでも構いません。お気軽にご相談ください。<br />
            御茶ノ水つばめ歯科・矯正歯科 院長 / 歯科医師　昭和大学歯学部卒<br />
            <br />
            長谷川 陽一

            <small
              style={{
                fontSize: '12px',
                color: 'rgba(0, 0, 0, 0.47)',
                display: 'inline-block',
                marginLeft: '4px',
              }}
            >Yoichi Hasegawa</small>
          </Typography>
          <Stack
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={2}
          >
            <img
              src="/home/hasegawa.jpg"
              width="180px"
              style={{
                display: 'block',
              }}
            />
          </Stack>

          <Box
            display="flex"
            justifyContent="center"
            gap={2}
            mt={4}
            flexWrap="wrap"
          >
            <a href="https://doctorsfile.jp/h/218253/df/1/" target="_blank">
              <img style={{ width: '250px' }} src="/banner/banner_001.jpg" />
            </a>
            <a href="https://shikamedi.com/2024/08/21/orthodontics/" target="_blank">
              <img style={{ width: '250px' }} src="/banner/banner_004.png" />
            </a>

            <a href="https://lit.link/tsubamedc" target="_blank">
              <Stack
                height="100px"
                width="250px"
                alignItems="center"
                justifyContent="center"
                sx={{
                  background: '#8a8a8a',
                }}
              >
                <img style={{ width: '250px' }} src="/banner/sns_banner.png" />
              </Stack>
            </a>

          </Box>

        </Stack>

        <Stack marginY={4}>
          <Access />
        </Stack>

        <Box
          marginY={4}
          display="flex"
          justifyContent="center"
          gap={3}
        >

          <Stack
            display="flex"
            justifyContent="center"
            gap={1}
            alignItems="center"
            padding={1}
            sx={{
              backgroundColor: '#595c77',
              border: '1px solid #575757',
              borderRadius: '16px',
              width: '300px',
              maxWidth: 'calc(50% - 16px)',
              '&:hover': {
                opacity: 0.8,
              }
            }}
            component="a"
            target="_blank"
            href="https://clinics-app.com/dental/c2be2993-6582-40bf-ba37-bcdcf6ff3ee2/exclusive_reservation"
            onClick={() => {
              if (typeof window.gtag === 'function') {
                window.gtag('event', 'click_web_reservation', {
                  event_category: 'button',
                  event_label: 'reservation_button',
                });
              }
            }}
          >
            <EventNoteIcon
              sx={{
                color: '#FFFFFF',
                fontSize: '28px',
              }}
            />
            <Typography variant="body1" sx={{ color: '#FFFFFF' }}>
              WEBで予約
            </Typography>
          </Stack>


          <Stack
            display="flex"
            justifyContent="center"
            gap={1}
            alignItems="center"
            padding={1}
            sx={{
              backgroundColor: '#FFFFFF',
              border: '1px solid #575757',
              borderRadius: '16px',
              width: '300px',
              maxWidth: 'calc(50% - 16px)',
              '&:hover': {
                opacity: 0.8,
              }
            }}
            component="a"
            href="tel:03-6281-7737"
            onClick={() => {
              if (typeof window.gtag === 'function') {
                window.gtag('event', 'click_tel_reservation', {
                  event_category: 'button',
                  event_label: 'reservation_button',
                });
              }
            }}
          >
            <SettingsPhoneIcon
              sx={{
                color: '#616161',
                fontSize: '28px',
              }}
            />
            <Typography variant="body1">
              電話予約
            </Typography>
          </Stack>
        </Box>
      </Container >
    </>
  )
}

export default Home
